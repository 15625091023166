/**
 * This function updates the status indicating whether the Live Chat functionality should currently be enabled or not.
 *
 * This status is stored within the global `state.config.liveChatEnabled` property, similar to other app state
 * properties. This property can then be used to conditionally render Live Chat-related components, depending on its
 * value. The live chat status is updated using the normal mechanisms to update the app state - i.e. by dispatching
 * the appropriate `setLiveChatDisabled()` or `setLiveChatEnabled()` actions, and then transforming the app state as
 * appropriate via the relevant reducer.
 *
 * Note that the Live Chat functionality may need to be disabled for a number of potential reasons, so this function
 * automatically disables the Live Chat if any of these reasons are currently applicable:
 *
 * - The Live Chat has been configured via the site settings not to appear on this site.
 * - The Live Chat provider URL (configured via the site settings) is not currently known.
 * - The user is not currently logged in.
 * - The user is currently offline.
 * - It is not currently a weekday (i.e. Monday - Friday).
 * - The current time of day is before the start time configured via the site settings.
 * - The current time of day is after the stop time configured via the site settings.
 *
 * If none of these reasons are currently applicable, the Live Chat is automatically enabled.
 *
 * If Live Chat logging is currently enabled within the relevant app environment, the reasons for disabling or enabling
 * the Live Chat will be logged to the browser console.
 *
 * ----------
 * - Usage: -
 * ----------
 *
 * 1. Import this function at the top of any file that needs to update the Live Chat status:
 *
 * import { updateLiveChatStatus } from '../data/config/updateLiveChatStatus';
 *
 * 2. Add code similar to this within the relevant file (note: this function could be potentially be called from within
 * a `useEffect()` React hook, to run the function only when certain conditions and/or events occur):
 *
 * updateLiveChatStatus(state.config.data, state.user.loggedIn, dispatch);
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */
import { setLiveChatDisabled, setLiveChatEnabled } from './config.actions';
import { logLiveChatStatus } from '../../helpers/openLiveChat';

export const updateLiveChatStatus = (configData, userLoggedIn, dispatch) => {
    // Check if Live Chat is not available on this site:
    if(configData.display_live_chat === 'no') {
        logLiveChatStatus('Disabling Live Chat: This has been configured not to display on this site.');
        dispatch(setLiveChatDisabled());
        return;
    }
    
    // Check if Live Chat provider URL is not known:
    if(configData.live_chat_url.length === 0) {
        logLiveChatStatus('Disabling Live Chat: Live Chat provider URL is not currently known.');
        dispatch(setLiveChatDisabled());
        return;
    }
    
    // Check if user is not logged in:
    if(!userLoggedIn) {
        logLiveChatStatus('Disabling Live Chat: User is not currently logged in.');
        dispatch(setLiveChatDisabled());
        return;
    }
    
    // Check if user is offline:
    if(!navigator.onLine) {
        logLiveChatStatus('Disabling Live Chat: User is currently offline.');
        dispatch(setLiveChatDisabled());
        return;
    }
    
    // Extract dates/times, so that further checks can be performed:
    const curDateTime = new Date();
    const splitStartTime = configData.live_chat_start_time.split(':');
    const startTime = new Date();
    startTime.setHours(parseInt(splitStartTime[0]), parseInt(splitStartTime[1]),0);
    const stopTime = new Date();
    const splitStopTime = configData.live_chat_stop_time.split(':');
    stopTime.setHours(parseInt(splitStopTime[0]), parseInt(splitStopTime[1]),0);
    
    // Check if it is not a weekday:
    if(curDateTime.getDay() === 6 || curDateTime.getDay() === 0) {
        logLiveChatStatus('Disabling Live Chat: It is currently a Saturday or Sunday.');
        dispatch(setLiveChatDisabled());
        return;
    }
    
    // Check if current time of day is before configured start time:
    if(curDateTime < startTime) {
        logLiveChatStatus('Disabling Live Chat: Current time (' + curDateTime + ') is before start time (' + startTime + ').');
        dispatch(setLiveChatDisabled());
        return;
    }
    
    // Check if current time of day is after configured stop time:
    if(curDateTime >= stopTime) {
        logLiveChatStatus('Disabling Live Chat: Current time (' + curDateTime + ') is after stop time (' + stopTime + ').');
        dispatch(setLiveChatDisabled());
        return;
    }
    
    // None of the checks have identified any reasons to disable the Live Chat, so it can now be enabled:
    logLiveChatStatus('Enabling Live Chat...');
    dispatch(setLiveChatEnabled());
}