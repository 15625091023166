/**
 * This function provides functionality to open a new window pointing to the URL of an external Live Chat provider.
 * It can be used to display this window when a link or button in the app is clicked.
 *
 * ----------
 * - Usage: -
 * ----------
 *
 * 1. Import this function at the top of any React component file that needs to show a Live Chat overlay/window:
 *
 * import { openLiveChat } from '../helpers/openLiveChat';
 *
 * 2. Add code similar to this within the component (this code will display the Live Chat overlay/window
 *    when the relevant element is clicked):
 *
 * <span onClick={() => openLiveChat(state)}>Live Chat</span>
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

export const openLiveChat = (state) => {
    if(!state.config.liveChatEnabled) {
       console.error('Live Chat is currently unavailable!');
       return;
    }
    
    if ( ! window.liveChatWindow || window.liveChatWindow.closed) {
        logLiveChatStatus('Opening Live Chat window with URL: ' + state.config.data.live_chat_url);
        window.liveChatWindow = window.open(
            state.config.data.live_chat_url,
            'ChatWindow',
            'menubar=0,location=0,scrollbars=auto,resizable=1,status=0,width=400,height=600'
        );
        // window.liveChatWindow.opener = null;
    } else {
        logLiveChatStatus('Live Chat window is already open - re-focussing window...');
        window.liveChatWindow.focus();
    }
}

/**
 * Log the current Live Chat status to the browser console, if this is enabled based on the configured
 * environment variables.
 *
 * @param {string} message
 *      Current Live Chat status message
 */
export const logLiveChatStatus = (message) => {
    if(
        process.env.REACT_APP_ENABLE_LIVE_CHAT_LOGGING &&
        process.env.REACT_APP_ENABLE_LIVE_CHAT_LOGGING === "1"
    ) {
        console.log(message);
    }
}